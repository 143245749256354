// components/Footer.tsx
import React from 'react';
import { Container } from 'react-bootstrap';

export const Footer: React.FC = () => {
  return (
    <footer className="footer mt-auto py-3">
      <Container>
        <span>&copy; 2024 YRIG Risk Retention Group, Inc. All rights reserved.</span>
      </Container>
    </footer>
  );
};
